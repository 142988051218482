export default {
    themes: {
        light: {
            primary: '#ea5403',
            secondary: '#2c3e50',
            accent: '#8c9eff',
            error: '#b71c1c',
            
        }
    }
}