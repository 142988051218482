var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}}),(!_vm.userDataReady)?_c('Loader',{attrs:{"title":"Chargement en cours","text":"Juste un instant s'il vous plaît."}}):_c('v-container',{staticClass:"constrained"},[(_vm.farmer)?_c('div',{staticClass:"ma-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"d-flex align-center pa-3",attrs:{"outlined":""}},[_c('div',{staticClass:"d-none d-sm-flex",staticStyle:{"margin-right":"20px"}},[_c('v-avatar',{attrs:{"color":"grey","size":"70"}},[(_vm.farmer.profile_image)?_c('v-img',{attrs:{"src":_vm.farmer.profile_image}}):_c('v-icon',[_vm._v("mdi-account")])],1)],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1",staticStyle:{"margin-right":"20px"}},[_c('v-card-title',{staticClass:"ma-0 pa-0"},[_vm._v(_vm._s(_vm.displayName))]),_c('div',[_vm._v(_vm._s(_vm.farmer.email))])],1),(_vm.farmer.approved)?_c('v-btn',{staticClass:"text-none",attrs:{"text":"","color":"primary"},on:{"click":_vm.seePublicProfile}},[_vm._v("Voir le profil public")]):_c('v-btn',{staticClass:"text-none",attrs:{"text":"","color":"grey-darken-2","disabled":""}},[_vm._v("Profil non publié")])],1)],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"min-height":"180px"},attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"fill-height",attrs:{"elevation":hover ? 4 : 2},on:{"click":_vm.editPersonalInformation}},[_c('v-card-title',[_vm._v(" Informations Personnelles "),_c('v-icon',{staticStyle:{"margin-top":"3px","margin-left":"5px"},attrs:{"small":"","color":"#333"}},[_vm._v("mdi-chevron-right")])],1),_c('v-card-subtitle',[_vm._v("Modifiez vos données et informations de contact")])],1)}}],null,false,1383470246)})],1),_c('v-col',{staticStyle:{"min-height":"180px"},attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"fill-height d-flex flex-column",attrs:{"outlined":"","elevation":hover ? 4 : 2},on:{"click":_vm.editFarm}},[_c('v-card-title',[_vm._v(" Exploitation "),_c('v-icon',{staticStyle:{"margin-top":"3px","margin-left":"5px"},attrs:{"small":"","color":"#333"}},[_vm._v("mdi-chevron-right")])],1),_c('v-card-subtitle',{staticClass:"flex-grow-1"},[_vm._v("Mettez à jour votre exploitation et votre démarche")]),(!_vm.farmer.approved)?_c('v-card-text',[_c('v-chip',{attrs:{"small":"","outlined":"","color":"amber darken-2"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-clock-outline")]),_vm._v("En attente de validation ")],1)],1):_vm._e()],1)}}],null,false,360233619)})],1)],1),_c('v-row',[_vm._l((_vm.approvedExperiments),function(experiment){return _c('v-col',{key:experiment.id,staticStyle:{"min-height":"180px"},attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"fill-height d-flex flex-column",attrs:{"outlined":"","elevation":hover ? 4 : 2},on:{"click":function($event){return _vm.goToExperiment(experiment)}}},[_c('v-card-title',[_vm._v(" Retour d'expérience "),_c('v-icon',{staticStyle:{"margin-top":"3px","margin-left":"5px"},attrs:{"small":"","color":"#333"}},[_vm._v("mdi-chevron-right")])],1),_c('v-card-subtitle',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(experiment.name))]),_c('v-card-text',[_c('v-chip',{attrs:{"small":"","outlined":"","color":"primary"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-check")]),_vm._v("En ligne ")],1)],1)],1)}}],null,true)})],1)}),_vm._l((_vm.pendingExperiments),function(experiment){return _c('v-col',{key:experiment.id,staticStyle:{"min-height":"180px"},attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"d-flex flex-column fill-height",attrs:{"outlined":"","elevation":hover ? 4 : 2},on:{"click":function($event){return _vm.goToExperiment(experiment)}}},[_c('v-card-title',[_vm._v(" Retour d'expérience "),_c('v-icon',{staticStyle:{"margin-top":"3px","margin-left":"5px"},attrs:{"small":"","color":"#333"}},[_vm._v("mdi-chevron-right")])],1),_c('v-card-subtitle',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(experiment.name))]),_c('v-card-text',[_c('v-chip',{attrs:{"small":"","outlined":"","color":"amber darken-2"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-clock-outline")]),_vm._v("En attente de validation ")],1)],1)],1)}}],null,true)})],1)}),_vm._l((_vm.draftExperiments),function(experiment){return _c('v-col',{key:experiment.id,staticStyle:{"min-height":"180px"},attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"d-flex flex-column fill-height",attrs:{"outlined":"","elevation":hover ? 4 : 2},on:{"click":function($event){return _vm.goToExperiment(experiment)}}},[_c('v-card-title',[_vm._v(" Retour d'expérience "),_c('v-icon',{staticStyle:{"margin-top":"3px","margin-left":"5px"},attrs:{"small":"","color":"#333"}},[_vm._v("mdi-chevron-right")])],1),_c('v-card-subtitle',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(experiment.name))]),_c('v-card-text',[_c('v-chip',{attrs:{"small":"","outlined":"","color":"blue-grey darken-2"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"small":""}},[_vm._v("mdi-lead-pencil")]),_vm._v("Brouillon ")],1)],1)],1)}}],null,true)})],1)}),_c('v-col',{staticStyle:{"min-height":"180px"},attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"fill-height d-flex",attrs:{"outlined":"","color":"#FFF","elevation":hover ? 4 : 2},on:{"click":_vm.createXP}},[_c('v-card-title',{staticClass:"align-center"},[_c('v-avatar',{staticStyle:{"margin-right":"5px"},attrs:{"size":"40","color":"primary"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-beaker-plus-outline")])],1),_vm._v("Ajouter une expérience ")],1)],1)}}],null,false,754858403)})],1)],2),_c('v-row',[_c('v-col',{staticStyle:{"min-height":"180px"},attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"fill-height",attrs:{"outlined":"","elevation":hover ? 4 : 2},on:{"click":_vm.goToMessages}},[_c('v-card-title',[_vm._v(" Messages "),(_vm.unreadMessageCount > 0)?_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("("+_vm._s(_vm.unreadMessageCount)+")")]):_vm._e(),_c('v-icon',{staticStyle:{"margin-top":"3px","margin-left":"5px"},attrs:{"small":"","color":"#333"}},[_vm._v("mdi-chevron-right")]),(_vm.unreadMessageCount > 0)?_c('v-badge',{staticStyle:{"position":"absolute","top":"13px","left":"7px"},attrs:{"dot":"","color":"amber"}}):_vm._e()],1),(_vm.unreadMessageCount > 0)?_c('v-card-subtitle',[_vm._v("Accèder à votre messagerie")]):_c('v-card-subtitle',[_vm._v("Vous n'avez pas de nouveaux messages")])],1)}}],null,false,141383579)})],1),_c('v-col',{staticStyle:{"min-height":"180px"},attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"fill-height",attrs:{"outlined":"","elevation":hover ? 4 : 2},on:{"click":_vm.goToHelp}},[_c('v-card-title',[_vm._v(" Aide "),_c('v-icon',{staticStyle:{"margin-top":"3px","margin-left":"5px"},attrs:{"small":"","color":"#333"}},[_vm._v("mdi-chevron-right")])],1),_c('v-card-subtitle',[_vm._v("Vous trouverez tous les articles d'aide, les ressources et les conseils dans le centre d'aide de REX Agri")])],1)}}],null,false,3332128991)})],1)],1)],1):_vm._e(),_c('v-btn',{staticStyle:{"margin-top":"20px"},attrs:{"outlined":""},on:{"click":_vm.logout}},[_vm._v("Se déconnecter")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }